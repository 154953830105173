import axios from 'axios';
import { useQuery } from 'react-query';
import { TournamentT } from '../../types/tournament';
export function useTournament(
  id: string | number
): [TournamentT | undefined, 'idle' | 'error' | 'loading' | 'success'] {
  const { data, status } = useQuery<TournamentT>(['tournaments', id], () =>
    axios(`/api/tournaments/${id}`).then((res) => res.data)
  );
  return [data, status];
}
