import axios from 'axios';
import { useQuery } from 'react-query';

export function useArmsList(): [
  string[] | undefined,
  'idle' | 'error' | 'loading' | 'success'
] {
  const { data, status } = useQuery<string[]>(['arms'], () =>
    axios(`/api/teams/arms`).then((res) => res.data)
  );

  return [data, status];
}
