import {
  MutationStatus,
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { FinalMatchT } from '../../types/match';

export const useUpdateFinalMatch = (): [
  UseMutateFunction<AxiosResponse<any>, unknown, FinalMatchT, unknown>,
  MutationStatus
] => {
  const queryClient = useQueryClient();

  const { mutate, status } = useMutation(
    (match: FinalMatchT) =>
      axios.put(`/api/final-matches/${match.id}`, {
        match,
      }),
    {
      onSuccess(_, match) {
        queryClient.invalidateQueries(['finalMatches', match.tourney_id]);
      },
    }
  );

  return [mutate, status];
};
