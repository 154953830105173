import { TournamentT } from '../../types/tournament';
import axios from 'axios';
import {
  MutationStatus,
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

interface PatchObjT {
  id: number;
  data: object;
}

export function useUpdateTournament(): [
  UseMutateFunction<TournamentT, unknown, PatchObjT, unknown>,
  MutationStatus
] {
  const queryClient = useQueryClient();
  const { mutate, status } = useMutation(
    (patchObj: PatchObjT) =>
      axios
        .patch<TournamentT>(`/api/tournaments/${patchObj.id}`, patchObj.data)
        .then((res) => res.data),
    {
      onSuccess(updatedTourney) {
        queryClient.setQueryData(
          'tournaments',
          (old: TournamentT[] | undefined) => {
            if (old === undefined) return [];
            return old.map((tourney) => {
              if (updatedTourney.id === tourney.id) return updatedTourney;
              return tourney;
            });
          }
        );
        queryClient.invalidateQueries([
          'tournaments',
          updatedTourney.id.toString(),
        ]);
      },
    }
  );

  return [mutate, status];
}
