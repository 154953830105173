import axios from 'axios';
import {
  MutationStatus,
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from 'react-query';
import { TournamentT } from '../../types/tournament';

export function useDeleteTournament(): [
  UseMutateFunction<TournamentT, unknown, number, unknown>,
  MutationStatus
] {
  const queryClient = useQueryClient();
  const { mutate, status } = useMutation(
    (id: number) =>
      axios
        .delete<TournamentT>(`/api/tournaments/${id}`)
        .then((res) => res.data),
    {
      onSuccess() {
        queryClient.invalidateQueries('tournaments');
      },
    }
  );
  return [mutate, status];
}
