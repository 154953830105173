import { createContext, ReactNode, useContext, useReducer } from 'react';

export type ActionT = 'SCOREBOARD_REORDERED';
export type StateT = typeof defaultState;
export type DispatchT = (action: ActionT) => void;

const defaultState = { finishedQueries: 0 };

const GroupContext = createContext<{
  dispatch: React.Dispatch<ActionT>;
  state: StateT;
}>({ dispatch: () => undefined, state: defaultState });

function groupReducer(state: StateT, action: ActionT): StateT {
  switch (action) {
    case 'SCOREBOARD_REORDERED':
      return {
        ...state,
        finishedQueries: state.finishedQueries + 1,
      };
  }
}

export function GroupContextProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(groupReducer, defaultState);

  return (
    <GroupContext.Provider value={{ state, dispatch }}>
      {children}
    </GroupContext.Provider>
  );
}

export function useGroupContext() {
  return useContext(GroupContext);
}
