import axios from 'axios';
import { useQuery } from 'react-query';
import { PlayerT } from '../../types/player';

export function usePlayers(
  userId: number
): [PlayerT[] | undefined, 'idle' | 'error' | 'loading' | 'success'] {
  const { data, status } = useQuery<PlayerT[]>(['players', userId], () =>
    axios(`/api/players/${userId}`).then((res) => res.data)
  );

  return [data, status];
}
