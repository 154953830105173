import React from 'react';
import { Badge, Table } from 'react-bootstrap';
import { MiniScoreboardT } from '../types/scoreboard';

interface Props {
  data: MiniScoreboardT;
}

export const MiniScoreboard: React.FC<Props> = ({ data }) => {
  return (
    <Table striped bordered variant='dark'>
      <thead>
        <tr>
          <th colSpan={10} className='text-center'>
            Mini Scoreboard {data[0].number}
          </th>
        </tr>
        <tr>
          <th>Name</th>
          <th>Played</th>
          <th>Points</th>
          <th>GF</th>
          <th>GA</th>
          <th>GD</th>
          <th>Won</th>
          <th>Tied</th>
          <th>Lost</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => {
          return (
            <tr key={row.team_id}>
              <th>
                <Badge>{row.in_group_id}</Badge>
                {row.name}
              </th>
              <td>{row.played_matches}</td>
              <th>{row.points}</th>
              <td>{row.scored_goals}</td>
              <td>{row.lost_goals}</td>
              <td>{row.goals_difference}</td>
              <td>{row.won_matches}</td>
              <td>{row.tied_matches}</td>
              <td>{row.lost_matches}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
