import React from 'react';
import { Card, ButtonGroup, Button, Form } from 'react-bootstrap';
import {
  faEdit,
  faEye,
  faEyeSlash,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TournamentT } from '../types/tournament';
import { useDeleteTournament } from '../hooks/tournaments/useDeleteTournament';
import { useState } from 'react';
import { SimpleModal } from './SimpleModal';
import { useUpdateTournament } from '../hooks/tournaments/useUpdateTournament';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';

interface Props {
  tournament: TournamentT;
}

export const TournamentListEl: React.FC<Props> = ({ tournament }) => {
  const [deleteTournament] = useDeleteTournament();
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [updateTournament] = useUpdateTournament();
  const [newName, setNewName] = useState('');
  const [place, setPlace] = useState('');
  const [date, setDate] = useState('');
  const [place2, setPlace2] = useState('');
  const [date2, setDate2] = useState('');
  const [color, setColor] = useState('');
  const [showRenameModal, setShowRenameModal] = useState(false);
  useEffect(() => {
    if (tournament.name) setNewName(tournament.name);
    if (tournament.place) setPlace(tournament.place);
    if (tournament.date) setDate(tournament.date);
    if (tournament.place2) setPlace2(tournament.place2);
    if (tournament.date2) setDate2(tournament.date2);
    if (tournament.color) setColor(tournament.color);
  }, [tournament]);

  const linkName = useMemo(
    () => tournament.name.replaceAll(' ', '-'),
    [tournament.name]
  );

  return (
    <Card className='m-3 tournament '>
      <Link
        to={`/tournament/${tournament.id}/${linkName}`}
        style={{ color: 'inherit', textDecoration: 'inherit' }}
      >
        <Card.Body className='d-flex justify-content-between align-items-center'>
          <p className='m-0'>{tournament.name}</p>
          <ButtonGroup>
            <Button
              variant={tournament.visibility ? 'warning' : 'secondary'}
              onClick={(e) => {
                updateTournament({
                  id: tournament.id,
                  data: { visibility: !tournament.visibility },
                });
                e.preventDefault();
              }}
            >
              <FontAwesomeIcon
                icon={tournament.visibility ? faEye : faEyeSlash}
              />
            </Button>

            <Button
              variant='info'
              onClick={(e) => {
                setShowRenameModal(true);
                e.preventDefault();
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>

            <Button
              variant='danger'
              onClick={(e) => {
                setShowDeletionModal(true);
                e.preventDefault();
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </ButtonGroup>
        </Card.Body>
      </Link>

      <SimpleModal
        confirmButtonText='Delete'
        confirmButtonVariant='danger'
        confirmFunction={() => deleteTournament(tournament.id)}
        headerText='Tournament deleting'
        setShow={setShowDeletionModal}
        show={showDeletionModal}
      >
        {`Are you sure you want to delete the tournament ${tournament.name}?`}
      </SimpleModal>

      <SimpleModal
        confirmButtonText='Update'
        confirmButtonVariant='success'
        confirmFunction={() => {
          updateTournament({
            id: tournament.id,
            data: { name: newName, place, date, place2, date2, color },
          });
          setShowRenameModal(false);
        }}
        headerText='Tournament renaming'
        setShow={setShowRenameModal}
        show={showRenameModal}
      >
        New name:
        <Form.Group>
          <Form.Control
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          Color: {color}
          <Form.Control
            type='color'
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
        </Form.Group>
        <h6>First day</h6>
        <Form.Group>
          Place:
          <Form.Control
            value={place}
            onChange={(e) => setPlace(e.target.value)}
          />
          Date:
          <Form.Control
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </Form.Group>
        <h6>Second day</h6>
        <Form.Group>
          Place:
          <Form.Control
            value={place2}
            onChange={(e) => setPlace2(e.target.value)}
          />
          Date:
          <Form.Control
            value={date2}
            onChange={(e) => setDate2(e.target.value)}
          />
        </Form.Group>
      </SimpleModal>
    </Card>
  );
};
