import React from 'react';
import { TournamentsPage } from './pages/TournamentsPage';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { TournamentPage } from './pages/TournamentPage';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GroupContextProvider } from './shared/groupContext';
import { Helmet } from 'react-helmet';
import { MenuPage } from './pages/MenuPage';
import { UsersPage } from './pages/UsersPage';
import { MessagesPage } from './pages/MessagesPage';
import { AnnouncementsPage } from './pages/AnnouncementsPage';
import { EditAnnouncementPage } from './pages/EditAnnouncementPage';

interface Props {}

export const App: React.FC<Props> = () => {
  return (
    <>
      <Helmet>
        <title>Tournaments Manager</title>
      </Helmet>
      <ReactQueryDevtools initialIsOpen={false} />
      <Router>
        <Switch>
          <Route path='/tournament/:id/:name' exact>
            <MenuPage />
          </Route>
          <Route path='/tournament/:id/:name/users' exact>
            <UsersPage />
          </Route>
          <Route path='/tournament/:id/:name/announcement' exact>
            <MessagesPage />
          </Route>
          <Route
            path='/tournament/:id/:name/announcement/edit/:announcement'
            exact
          >
            <EditAnnouncementPage />
          </Route>
          <Route path='/tournament/:id/:name/announcements' exact>
            <AnnouncementsPage />
          </Route>
          <Route path='/tournament/:id/:name/matches' exact>
            <GroupContextProvider>
              <TournamentPage />
            </GroupContextProvider>
          </Route>
          <Route path='/'>
            <TournamentsPage />
          </Route>
        </Switch>
      </Router>
    </>
  );
};
