import React from 'react';
import { Container } from 'react-bootstrap';
import { CreateTournamentButton } from '../tournaments/CreateTournamentButton';
import { useTournaments } from '../hooks/tournaments/useTournaments';
import { TournamentListEl } from '../tournaments/TournamentsListEl';
import './TournamentsPage.css';

interface Props {}

export const TournamentsPage: React.FC<Props> = () => {
  const [tournaments] = useTournaments();

  return (
    <Container className='tournaments-page'>
      <h1 className='text-center m-3 mb-5'>Salt Mine Cup</h1>
      <div className='button-box d-flex justify-content-end m-3'>
        <CreateTournamentButton />
      </div>
      {tournaments &&
        tournaments.map((tournament) => (
          <TournamentListEl key={tournament.id} tournament={tournament} />
        ))}
    </Container>
  );
};
