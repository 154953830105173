import { TeamT } from '../../types/team';
import axios from 'axios';
import { useQuery } from 'react-query';

export function useTeams(
  tourneyId: number,
  group: string
): [TeamT[] | undefined, 'idle' | 'error' | 'loading' | 'success'] {
  const { data, status } = useQuery<TeamT[]>(['teams', tourneyId, group], () =>
    axios(`/api/tournaments/${tourneyId}/groups/${group}/teams`).then(
      (res) => res.data
    )
  );

  return [data, status];
}
