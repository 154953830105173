import { TournamentT, NewTournamentT } from '../../types/tournament';
import {
  useMutation,
  MutationStatus,
  UseMutateFunction,
  useQueryClient,
} from 'react-query';
import axios from 'axios';
export function useCreateTournament(): [
  UseMutateFunction<TournamentT, unknown, NewTournamentT, unknown>,
  MutationStatus
] {
  const queryClient = useQueryClient();
  const { mutate, status } = useMutation(
    (tournament: NewTournamentT) =>
      axios
        .post<TournamentT>('/api/tournaments', { tournament })
        .then((res) => res.data),
    {
      onSuccess() {
        queryClient.invalidateQueries('tournaments');
      },
    }
  );

  return [mutate, status];
}
