import 'bootstrap/dist/css/bootstrap.css';
import ReactDOM from 'react-dom';
import { App } from './App';
import { QueryClient, QueryClientProvider } from 'react-query';
import axios from 'axios';
import getToken from './shared/token';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

axios.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Headers'] =
  'Origin, X-Requested-With, Content-Type, Accept';
export const API_URL = process.env.REACT_APP_API_URL;
axios.defaults.baseURL = API_URL;

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>,
  document.getElementById('root')
);
