import React from "react";
import { Card } from "react-bootstrap";
import { useTeams } from "../hooks/teams/useTeams";
import { Team } from "./Team";
import { TeamT } from "../types/team";

interface Props {
  group: string;
  tourneyId: number;
}

export const GroupMembers: React.FC<Props> = ({ group, tourneyId }) => {
  const [teams, status] = useTeams(tourneyId, group);

  return (
    <>
      <h2 className="text-center mt-3">Group {group}</h2>
      <Card>
        <Card.Body>
          {status === "success" &&
            teams &&
            teams.map((team: TeamT, key: number) => (
              <Team team={team} key={key} />
            ))}
        </Card.Body>
      </Card>
    </>
  );
};
