import React, { useState } from 'react';
import { useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useUpdateTeam } from '../hooks/teams/useUpdateTeam';
import { useUsers } from '../hooks/users/useUsers';
import { TeamT } from '../types/team';

interface Props {
  team: TeamT;
}

export const Team: React.FC<Props> = ({ team }) => {
  const [updateTeam, status] = useUpdateTeam();
  const [isValid, setIsValid] = useState(false);
  const [timeoutHandler, setTimeoutHandler] = useState<NodeJS.Timeout | null>(
    null
  );
  const [userId, setUserId] = useState<number | null>(team.user_id);
  const [users] = useUsers(String(team.tourney_id));

  useEffect(() => {
    if (timeoutHandler !== null) {
      clearTimeout(timeoutHandler);
      setTimeoutHandler(null);
    }
    if (status === 'success') {
      setIsValid(true);
      setTimeoutHandler(setTimeout(() => setIsValid(false), 5000));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className='d-flex'>
      <InputGroup className='mb-2'>
        <InputGroup.Text>{team.in_group_id}</InputGroup.Text>
        <Form.Control
          as='select'
          value={userId || undefined}
          onChange={(e) => {
            if (e.target.value === 'None') setUserId(null);
            else setUserId(Number(e.target.value));
          }}
          isValid={isValid}
          isInvalid={status === 'error'}
          onBlur={() => updateTeam({ id: team.id, data: { user_id: userId } })}
        >
          <option value={undefined}>None</option>
          {users &&
            users
              .filter((user) => user.name !== '')
              .map((user) => (
                <option value={user.id} key={user.id}>
                  {user.name}
                </option>
              ))}
        </Form.Control>
      </InputGroup>
    </div>
  );
};
