import axios from 'axios';
import {
  MutationStatus,
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from 'react-query';
import { AnnouncementT } from '../../types/announcement';
interface PatchObjT {
  id: number;
  data: {
    [key in keyof AnnouncementT]?: AnnouncementT[key];
  };
}

export function useUpdateAnnouncement(
  tourneyId: string
): [
  UseMutateFunction<AnnouncementT, unknown, PatchObjT, unknown>,
  MutationStatus,
  boolean
] {
  const queryClient = useQueryClient();
  const { mutate, status, isLoading } = useMutation(
    (patchObj: PatchObjT) =>
      axios
        .patch<AnnouncementT>(
          `/api/announcements/${patchObj.id}`,
          patchObj.data
        )
        .then((res) => res.data),
    {
      onSuccess() {
        queryClient.invalidateQueries(['announcements', tourneyId]);
      },
    }
  );
  return [mutate, status, isLoading];
}
