import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FinalMatchesTable } from '../finals/FinalMatchesTable';
import { GroupMembers } from '../group/GroupMembers';
import { useScoreboards } from '../hooks/scoreboards/useScoreboards';
import { Schedule } from '../group/Schedule';
import { Scoreboard } from '../group/Scoreboard';
import { useTournament } from '../hooks/tournaments/useTournament';

interface Props {
  tournamentId: number;
}

export const GroupStagePage: React.FC<Props> = ({ tournamentId }) => {
  const [scoreboardsA] = useScoreboards(tournamentId, 'A');
  const [scoreboardsB] = useScoreboards(tournamentId, 'B');
  const [buttonVisibility, setButtonVilibility] = useState(false);
  const [reorder, setReorder] = useState(false);
  const [tournament] = useTournament(tournamentId);

  useEffect(() => {
    const areBothStatusesSetToReordering =
      scoreboardsA?.status === scoreboardsB?.status &&
      scoreboardsA?.status === 'matches are finished';

    setButtonVilibility(areBothStatusesSetToReordering);
  }, [scoreboardsA, scoreboardsB]);
  const handleClick = () => {
    setReorder(true);
  };

  return (
    <Container>
      <Row>
        <Col xs='12' lg='6'>
          <GroupMembers group='A' tourneyId={tournamentId} />
        </Col>
        <Col xs='12' lg='6'>
          <GroupMembers group='B' tourneyId={tournamentId} />
        </Col>
      </Row>
      {tournament && [24, 246, 32].includes(tournament.system) && (
        <Row>
          <Col xs='12' lg='6'>
            <GroupMembers group='C' tourneyId={tournamentId} />
          </Col>
          <Col xs='12' lg='6'>
            <GroupMembers group='D' tourneyId={tournamentId} />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Schedule tourneyId={tournamentId} />
        </Col>
      </Row>
      <Scoreboard tourneyId={tournamentId} group='A' shouldReorder={reorder} />
      <Scoreboard tourneyId={tournamentId} group='B' shouldReorder={reorder} />
      {tournament && [24, 246, 32].includes(tournament.system) && (
        <>
          <Scoreboard
            tourneyId={tournamentId}
            group='C'
            shouldReorder={reorder}
          />
          <Scoreboard
            tourneyId={tournamentId}
            group='D'
            shouldReorder={reorder}
          />
        </>
      )}

      <Row>
        <Col>
          {buttonVisibility && (
            <Button
              className='py-3 my-3'
              variant='success'
              size='lg'
              style={{ width: '100%' }}
              onClick={handleClick}
            >
              Finish the group stage
            </Button>
          )}
        </Col>
      </Row>

      <Row>
        <FinalMatchesTable
          tourneyId={tournamentId}
          displayScoreAndTeams={false}
        />
      </Row>
    </Container>
  );
};
