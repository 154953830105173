import axios from 'axios';
import { useQuery } from 'react-query';
import { TournamentT } from '../../types/tournament';
export function useTournaments(): [
  TournamentT[] | undefined,
  'idle' | 'error' | 'loading' | 'success'
] {
  const { data, status } = useQuery<TournamentT[]>('tournaments', () =>
    axios(`/api/tournaments`).then((res) => res.data)
  );
  return [data, status];
}
