import { TeamT } from '../../types/team';
import axios from 'axios';
import { MutationStatus, UseMutateFunction, useMutation } from 'react-query';
interface PatchObjT {
  id: number;
  data: object;
}

export function useUpdateTeam(): [
  UseMutateFunction<TeamT, unknown, PatchObjT, unknown>,
  MutationStatus
] {
  const { mutate, status } = useMutation((patchObj: PatchObjT) =>
    axios
      .patch<TeamT>(`/api/teams/${patchObj.id}`, patchObj.data)
      .then((res) => res.data)
  );
  return [mutate, status];
}
