import axios from 'axios';
import {
  MutationStatus,
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from 'react-query';
import { AnnouncementT } from '../../types/announcement';

export function useDeleteAnnouncement(
  tourneyId: string
): [
  UseMutateFunction<AnnouncementT, unknown, number, unknown>,
  MutationStatus
] {
  const queryClient = useQueryClient();
  const { mutate, status } = useMutation(
    (id: number) =>
      axios
        .delete<AnnouncementT>(`/api/announcements/${id}`)
        .then((res) => res.data),
    {
      onSuccess() {
        queryClient.invalidateQueries(['announcements', tourneyId]);
      },
    }
  );
  return [mutate, status];
}
