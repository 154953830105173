import React, { MouseEventHandler } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface Props {
  show: boolean;
  setShow: (a: boolean) => void;
  headerText: string;
  confirmButtonText: string;
  confirmFunction: MouseEventHandler<HTMLElement>;
  confirmButtonVariant: string;
  size?: 'sm' | 'lg' | 'xl';
}

export const SimpleModal: React.FC<Props> = (props) => {
  const {
    show,
    setShow,
    headerText,
    confirmButtonText,
    confirmFunction,
    confirmButtonVariant,
    size,
    children,
  } = props;
  return (
    <Modal size={size} show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{headerText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setShow(false)}>
          Cancel
        </Button>
        <Button variant={confirmButtonVariant} onClick={confirmFunction}>
          {confirmButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
