import React, { ChangeEvent, useEffect, useState } from 'react';
import { Badge, Form, Table } from 'react-bootstrap';
import { useScoreboards } from '../hooks/scoreboards/useScoreboards';
import { useUpdateOrder } from '../hooks/scoreboards/useUpdateOrder';
import { MiniScoreboard } from './MiniScoreboard';

interface Props {
  tourneyId: number;
  group: string;
  shouldReorder: boolean;
}

export const Scoreboard: React.FC<Props> = ({
  tourneyId,
  group,
  shouldReorder,
}) => {
  const [scoreboards] = useScoreboards(tourneyId, group);
  const [inpValues, setInpValues] = useState<{ val: number[] }>({
    val: [1, 2, 3, 4, 5, 6, 7, 8],
  });
  const [updateOrder] = useUpdateOrder(tourneyId, group);

  useEffect(() => {
    if (!shouldReorder) return;

    updateOrder(inpValues.val);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldReorder]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const index = parseInt(e.target.id);
    let vals = [...inpValues.val];
    vals[index] = parseInt(e.target.value);
    setInpValues({ val: vals });
  };

  return (
    <>
      <h2 className='text-center my-3'>Group {group}</h2>
      {scoreboards && (
        <Table striped bordered>
          <thead>
            <tr>
              <th>No.</th>
              <th>Name</th>
              <th>Played</th>
              <th>Points</th>
              <th>GF</th>
              <th>GA</th>
              <th>GD</th>
              <th>Won</th>
              <th>Tied</th>
              <th>Lost</th>
              {scoreboards.status === 'matches are finished' && (
                <>
                  <th>Info</th>
                  <th>Order</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {scoreboards.scoreboard.map((row) => {
              return (
                <tr key={row.id}>
                  <td>{row.team_place}</td>
                  <th>
                    <Badge>{row.in_group_id}</Badge>
                    {row.name}
                  </th>
                  <td>{row.played_matches}</td>
                  <th>{row.points}</th>
                  <td>{row.scored_goals}</td>
                  <td>{row.lost_goals}</td>
                  <td>{row.goals_difference}</td>
                  <td>{row.won_matches}</td>
                  <td>{row.tied_matches}</td>
                  <td>{row.lost_matches}</td>
                  {scoreboards.status === 'matches are finished' && (
                    <>
                      <td>{row.info}</td>
                      <td>
                        <Form.Control
                          type='number'
                          value={inpValues.val[row.team_place - 1] || ''}
                          id={(row.team_place - 1).toString()}
                          onChange={handleChange}
                          min={1}
                          max={8}
                          style={{ width: '60px' }}
                        />
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      {scoreboards &&
        scoreboards.miniScoreboards.map((miniScoreboard) => (
          <MiniScoreboard
            data={miniScoreboard}
            key={miniScoreboard[0].number}
          />
        ))}
    </>
  );
};
