import React from 'react';
import { Table } from 'react-bootstrap';
import { FinalMatch } from './FinalMatch';
import { useFinalMatches } from '../hooks/matches/useFinalMatches';

interface Props {
  tourneyId: number;
  displayScoreAndTeams?: boolean;
}

export const FinalMatchesTable: React.FC<Props> = ({
  tourneyId,
  displayScoreAndTeams = true,
}) => {
  const [matches, status] = useFinalMatches(tourneyId);

  return (
    <>
      <h2 className='text-center w-100'>Finals</h2>
      <Table bordered>
        <thead>
          <tr>
            <th>No.</th>
            <th>Final Group</th>
            <th>Time</th>
            <th>Main info</th>
            <th>Side note</th>
            <th>Field</th>
            {displayScoreAndTeams && (
              <>
                <th colSpan={2}>Teams</th>
                <th colSpan={2}>Score</th>
                <th colSpan={2}>Penalty</th>
              </>
            )}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {status === 'success' &&
            matches?.map((match, index) => (
              <FinalMatch
                key={index}
                data={match}
                displayScoreAndTeams={displayScoreAndTeams}
              />
            ))}
        </tbody>
      </Table>
    </>
  );
};
