import { GroupMatchT } from '../../types/match';
import axios from 'axios';
import { QueryObserverResult, RefetchOptions, useQuery } from 'react-query';

export const useGroupMatches = (
  tourneyId: number
): [
  GroupMatchT[] | undefined,
  'idle' | 'error' | 'loading' | 'success',
  (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<GroupMatchT[], unknown>>
] => {
  const { data, status, refetch } = useQuery<GroupMatchT[]>(
    ['groupMatches', tourneyId],
    () =>
      axios(`/api/tournaments/${tourneyId}/group-matches`).then(
        (res) => res.data
      )
  );

  return [data, status, refetch];
};
