import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useCreateTournament } from '../hooks/tournaments/useCreateTournament';
import { SimpleModal } from './SimpleModal';

interface Props {}

export const CreateTournamentButton: React.FC<Props> = () => {
  const [createTournament] = useCreateTournament();
  const [showModal, setShowMd] = useState(false);
  const [name, setName] = useState('');
  const [place, setPlace] = useState('');
  const [date, setDate] = useState('');
  const [place2, setPlace2] = useState('');
  const [date2, setDate2] = useState('');
  const defaultColor = '#0800ff';
  const [color, setColor] = useState(defaultColor);
  const [visibility, setVisibility] = useState(false);
  const [system, setSystem] = useState(16);

  const setShowModal = (val: boolean) => {
    setShowMd(val);
    setName('');
    setPlace('');
    setDate('');
    setPlace2('');
    setDate2('');
    setVisibility(false);
    setColor(defaultColor);
    setSystem(16);
  };

  return (
    <>
      <Button variant='success' onClick={() => setShowModal(true)}>
        Add tournament
      </Button>
      <SimpleModal
        confirmButtonText='Add'
        confirmButtonVariant='primary'
        confirmFunction={() => {
          createTournament({
            name,
            visibility,
            status: 'group stage',
            place,
            date,
            place2,
            date2,
            color,
            system,
          });
          setShowModal(false);
        }}
        headerText='Creating tournament'
        setShow={setShowModal}
        show={showModal}
      >
        <Form.Group>
          <Form.Label>Name:</Form.Label>
          <Form.Control
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>System:</Form.Label>
          <Form.Control
            as='select'
            value={system}
            onChange={(e) => setSystem(parseInt(e.target.value))}
          >
            <option value={16}>16 drużynowy</option>
            <option value={24}>24 drużynowy</option>
            <option value={246}>24 drużynowy - 6 boisk</option>
            <option value={32}>32 drużynowy</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Color: {color}</Form.Label>
          <Form.Control
            type='color'
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
        </Form.Group>
        <h6>First day</h6>
        <Form.Group>
          <Form.Label>Place:</Form.Label>
          <Form.Control
            value={place}
            onChange={(e) => setPlace(e.target.value)}
          />
          <Form.Label>Date:</Form.Label>
          <Form.Control
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </Form.Group>
        <h6>Second day</h6>
        <Form.Group>
          <Form.Label>Place:</Form.Label>
          <Form.Control
            value={place2}
            onChange={(e) => setPlace2(e.target.value)}
          />
          <Form.Label>Date:</Form.Label>
          <Form.Control
            value={date2}
            onChange={(e) => setDate2(e.target.value)}
          />
        </Form.Group>
        <Form.Check
          label='Visibility'
          onChange={(e) => setVisibility(e.target.checked)}
        />
      </SimpleModal>
    </>
  );
};
