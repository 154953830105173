import axios from 'axios';
import { UseMutateFunction, useMutation } from 'react-query';
import { useGroupContext } from '../../shared/groupContext';

export const useUpdateOrder = (
  tourneyId: number,
  groupName: string
): [UseMutateFunction<any, unknown, number[], unknown>, boolean] => {
  const context = useGroupContext();
  const { mutate, isLoading } = useMutation(
    (order: number[]) =>
      axios
        .put(
          `/api/tournaments/${tourneyId}/groups/${groupName}/scoreboard/order`,
          { order }
        )
        .then((res) => res.data),
    {
      onSuccess() {
        context?.dispatch('SCOREBOARD_REORDERED');
      },
    }
  );

  return [mutate, isLoading];
};
