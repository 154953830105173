import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { AnnouncementListItem } from '../announcements/AnnouncementListItem';
import { useAnnouncements } from '../hooks/announcements/useAnnouncements';
import { useTournament } from '../hooks/tournaments/useTournament';

interface Props {}

export const AnnouncementsPage: React.FC<Props> = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string; name: string }>();
  const [tournament] = useTournament(id);
  const [announcements = []] = useAnnouncements(id);

  return (
    <div>
      {tournament && (
        <div className='position-relative'>
          <Link
            to={`/tournament/${id}/${tournament.name}`}
            className='position-absolute start-0 my-3 mx-5'
          >
            Go Back
          </Link>
          <h1 className='text-center m-3'>{tournament.name}</h1>
          <h3 className='text-center m-2 mb-4'>
            {tournament.date} {tournament.place}
          </h3>
        </div>
      )}

      {announcements.map((announcement) => (
        <AnnouncementListItem announcement={announcement} />
      ))}
    </div>
  );
};
