import React, { useEffect, useState } from 'react';
import { Badge, Form, Spinner } from 'react-bootstrap';
import { useUpdateGroupMatch } from '../hooks/matches/useUpdateGroupMatch';
import { GroupMatchT } from '../types/match';

interface Props {
  match: GroupMatchT;
}

export const GroupMatch: React.FC<Props> = ({ match }) => {
  const [time, setTime] = useState('');
  const [field, setField] = useState('');
  const [team1Goals, setTeam1Goals] = useState('');
  const [team2Goals, setTeam2Goals] = useState('');

  useEffect(() => {
    if (match.field !== null) setField(match.field.toString());
  }, [match.field]);

  useEffect(() => {
    if (match.time !== null) setTime(match.time);
  }, [match.time]);

  useEffect(() => {
    if (match.score[0] !== null) setTeam1Goals(match.score[0].toString());
    if (match.score[1] !== null) setTeam2Goals(match.score[1].toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(match.score)]);

  const [updateMatch, status] = useUpdateGroupMatch();

  const save = () => {
    const updatedMatch: GroupMatchT = {
      ...match,
      time,
      field: parseInt(field),
      score: [parseInt(team1Goals), parseInt(team2Goals)],
    };
    updateMatch(updatedMatch);
  };

  return (
    <tr>
      <td className='align-middle'>{match.number}</td>
      <td className='align-middle'>
        <Form.Control
          style={{ width: '120px' }}
          value={time}
          onChange={(e) => setTime(e.target.value)}
          onBlur={save}
        />
      </td>
      <td className='align-middle'>{match.group}</td>
      <td className='align-middle'>
        <Form.Control
          type='number'
          min={1}
          style={{ width: '70px' }}
          value={field}
          onChange={(e) => setField(e.target.value)}
          onBlur={save}
        />
      </td>
      <td className='align-middle'>
        <Badge>{match.teams_in_group_ids[0]}</Badge>
        {match.teams_names[0]}
      </td>
      <td className='align-middle'>
        <Badge>{match.teams_in_group_ids[1]}</Badge>
        {match.teams_names[1]}
      </td>
      <td className='align-middle'>
        <Form.Control
          type='number'
          min={0}
          style={{ width: '70px' }}
          value={team1Goals}
          onChange={(e) => setTeam1Goals(e.target.value)}
          onBlur={save}
        />
      </td>
      <td className='align-middle'>
        <Form.Control
          type='number'
          min={0}
          style={{ width: '70px' }}
          value={team2Goals}
          onChange={(e) => setTeam2Goals(e.target.value)}
          onBlur={save}
        />
      </td>
      <td className='align-middle'>
        {status === 'loading' && (
          <Spinner animation='border' role='status'></Spinner>
        )}
        {status === 'success' && <h6 className='text-success'>Saved</h6>}
        {status === 'error' && <h6 className='text-danger'>Error</h6>}
        {status === 'idle' && <h6 className='text-secondary'>Idle</h6>}
      </td>
    </tr>
  );
};
