import axios from 'axios';
import { useQuery } from 'react-query';
import { FinalMatchT } from '../../types/match';

export function useFinalMatches(
  tourneyId: number
): [FinalMatchT[] | undefined, 'idle' | 'error' | 'loading' | 'success'] {
  const { data, status } = useQuery<FinalMatchT[]>(
    ['finalMatches', tourneyId],
    () =>
      axios(`/api/tournaments/${tourneyId}/final-matches`).then(
        (res) => res.data
      )
  );

  return [data, status];
}
