import axios from 'axios';
import {
  useMutation,
  MutationStatus,
  UseMutateFunction,
  useQueryClient,
} from 'react-query';
import { GroupMatchT } from '../../types/match';

type GroupMatchUpdateResponseT = [{ id: number }, any, any];

export function useUpdateGroupMatch(): [
  UseMutateFunction<GroupMatchUpdateResponseT, unknown, GroupMatchT, unknown>,
  MutationStatus
] {
  const queryClient = useQueryClient();
  const { mutate, status } = useMutation(
    (updateObj: GroupMatchT) =>
      axios
        .put<GroupMatchUpdateResponseT>(
          `/api/group-matches/${updateObj.id}`,
          updateObj
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['scoreboards']);
      },
    }
  );

  return [mutate, status];
}
