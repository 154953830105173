import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import { Link, useRouteMatch } from "react-router-dom";
import { useDeleteAnnouncement } from "../hooks/announcements/useDeleteAnnouncement";
import { useTournament } from "../hooks/tournaments/useTournament";
import { SimpleModal } from "../tournaments/SimpleModal";
import { AnnoucementWithRecipientsT } from "../types/announcement";

interface Props {
  announcement: AnnoucementWithRecipientsT;
}

export const AnnouncementListItem: React.FC<Props> = ({ announcement }) => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string; name: string }>();
  const [tournament] = useTournament(id);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [deleteAnnouncement] = useDeleteAnnouncement(id);

  return (
    <>
      <Card className="m-3 tournament mx-auto" style={{ maxWidth: 1200 }}>
        {tournament && (
          <Link
            to={`/tournament/${tournament.id}/${tournament.name}/announcement/edit/${announcement.id}`}
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            <Card.Body className="">
              <Button
                className="float-right"
                variant="danger"
                onClick={(e) => {
                  setShowDeletionModal(true);
                  e.preventDefault();
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
              <div
                dangerouslySetInnerHTML={{ __html: announcement.content }}
              ></div>
            </Card.Body>
          </Link>
        )}
      </Card>
      <SimpleModal
        confirmButtonText="Delete"
        confirmButtonVariant="danger"
        confirmFunction={() => {
          deleteAnnouncement(announcement.id);
          setShowDeletionModal(false);
        }}
        headerText="Tournament deleting"
        setShow={setShowDeletionModal}
        show={showDeletionModal}
      >
        {`Are you sure you want to delete the announcement with id: ${announcement.id}?`}
      </SimpleModal>
    </>
  );
};
