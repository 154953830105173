import axios from 'axios';
import { useQuery } from 'react-query';
import { AnnoucementWithRecipientsT } from '../../types/announcement';

export function useAnnouncements(
  tourneyId: string
): [
  AnnoucementWithRecipientsT[] | undefined,
  'idle' | 'error' | 'loading' | 'success'
] {
  const { data, status } = useQuery<AnnoucementWithRecipientsT[]>(
    ['announcements', tourneyId],
    () =>
      axios(`/api/announcements?tournamentId=${tourneyId}`).then(
        (res) => res.data
      )
  );

  return [data, status];
}
