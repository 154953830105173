import axios from 'axios';
import { useQuery } from 'react-query';
import { MiniScoreboardT, ScoreboardT } from '../../types/scoreboard';

interface ScoreboardsObjectT {
  scoreboard: ScoreboardT;
  miniScoreboards: MiniScoreboardT[];
  status: string;
}

export const useScoreboards = (
  tourneyId: number,
  groupName: string
): [
  ScoreboardsObjectT | undefined,
  'idle' | 'error' | 'loading' | 'success'
] => {
  const scoreboardsQuery = useQuery<ScoreboardsObjectT>(
    ['scoreboards', tourneyId, groupName],
    () =>
      axios(
        `/api/tournaments/${tourneyId}/groups/${groupName}/scoreboards`
      ).then((res) => res.data)
  );
  return [scoreboardsQuery.data, scoreboardsQuery.status];
};
