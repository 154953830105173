import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Link, useRouteMatch } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { useAnnouncements } from '../hooks/announcements/useAnnouncements';
import { useTournament } from '../hooks/tournaments/useTournament';
import { useUpdateAnnouncement } from '../hooks/announcements/useUpdateAnnouncement';

interface Props {}

export const EditAnnouncementPage: React.FC<Props> = () => {
  const {
    params: { id, announcement: announcementId },
  } = useRouteMatch<{ id: string; name: string; announcement: string }>();
  const [tournament] = useTournament(id);
  const [announcements] = useAnnouncements(id);
  const announcement = useMemo(
    () => announcements?.find((a) => a.id === Number(announcementId)),
    [announcements, announcementId]
  );
  const [isAgenda, setIsAgenda] = useState(false);
  useEffect(() => {
    if (announcement) setIsAgenda(announcement.is_agenda);
  }, [announcement]);

  const [updateAnnouncement, status, isLoading] = useUpdateAnnouncement(id);

  const editorRef = useRef<Editor>(null);
  const updateMessage = () => {
    if (!editorRef.current) return;
    if (announcement === undefined) return;
    updateAnnouncement({
      id: announcement.id,
      data: {
        content: (editorRef.current as any).getContent(),
        is_agenda: isAgenda,
      },
    });
  };

  return (
    <div>
      {tournament && (
        <div>
          <div className='position-relative'>
            <Link
              to={`/tournament/${id}/${tournament.name}/announcements`}
              className='position-absolute start-0 my-3 mx-5'
            >
              Go Back
            </Link>
            <h1 className='text-center m-3'>{tournament.name}</h1>
          </div>
        </div>
      )}

      <div className='d-flex'>
        <div className='flex-grow-1 ml-5'>
          <Editor
            apiKey='lx2d82ilztf6wyi2cuzmrebfcepzzr9jqwuktzg1np5r97to'
            onInit={(_evt, editor) => {
              // @ts-ignore
              editorRef.current = editor;
            }}
            initialValue={announcement?.content || ''}
            init={{
              height: 500,
              menubar: true,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />
          {isLoading ? (
            <div>
              <Spinner animation='border' variant='primary' />
            </div>
          ) : (
            <Button className='w-100 my-2' onClick={() => updateMessage()}>
              Update message
            </Button>
          )}
          <h5>Status: {status}</h5>
        </div>
        <div className='px-5'>
          <h4>Options: </h4>
          <Form.Check
            className='my-2'
            checked={isAgenda}
            label='Is Agenda'
            onChange={(e) => setIsAgenda(e.target.checked)}
          />
          <h4 className='mt-3'>Recipients:</h4>
          {announcement?.recipients.map((r) => (
            <p>{r.name}</p>
          ))}
        </div>
      </div>
    </div>
  );
};
