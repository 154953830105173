import axios from 'axios';
import {
  MutationStatus,
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from 'react-query';
import { UserT } from '../../types/user';
interface PatchObjT {
  id: number;
  data: object;
}

export function useUpdateUser(): [
  UseMutateFunction<UserT, unknown, PatchObjT, unknown>,
  MutationStatus
] {
  const queryClient = useQueryClient();
  const { mutate, status } = useMutation(
    (patchObj: PatchObjT) =>
      axios
        .patch<UserT>(`/api/users/${patchObj.id}`, patchObj.data)
        .then((res) => res.data),
    {
      onSuccess(data, pathObj) {
        queryClient.invalidateQueries('users');
      },
    }
  );
  return [mutate, status];
}
