import React, { useRef, useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { useTournament } from "../hooks/tournaments/useTournament";
import { Editor } from "@tinymce/tinymce-react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useUsers } from "../hooks/users/useUsers";
import { useCreateAnnouncement } from "../hooks/announcements/useCreateAnnouncement";

interface Props {}

export const MessagesPage: React.FC<Props> = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string; name: string }>();
  const [tournament] = useTournament(id);
  const [users = []] = useUsers(id);
  const [createAnnouncement, status, isLoading] = useCreateAnnouncement(id);

  const [selectedUsersIds, setSelectedUsersIds] = useState<number[]>([]);
  const [isAgenda, setIsAgenda] = useState(false);

  const editorRef = useRef<Editor>(null);
  const sendMessage = () => {
    if (!editorRef.current) return;
    createAnnouncement({
      users: selectedUsersIds,
      content: (editorRef.current as any).getContent(),
      isAgenda,
    });
  };

  return (
    <div>
      {tournament && (
        <div className="position-relative">
          <Link
            to={`/tournament/${id}/${tournament.name}`}
            className="position-absolute start-0 my-3 mx-5"
          >
            Go Back
          </Link>
          <h1 className="text-center m-3">{tournament.name}</h1>
          <h3 className="text-center m-2 mb-4">
            {tournament.date} {tournament.place}
          </h3>
        </div>
      )}

      <div className="d-flex">
        <div className="flex-grow-1 ml-5">
          <Editor
            apiKey="lx2d82ilztf6wyi2cuzmrebfcepzzr9jqwuktzg1np5r97to"
            // @ts-ignore
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue=""
            init={{
              height: 500,
              menubar: true,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          {isLoading ? (
            <div className="d-flex justify-content-center my-2">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <Button className="w-100 my-2" onClick={sendMessage}>
              Send message
            </Button>
          )}
          <h5>Status: {status}</h5>
        </div>
        <div className="px-5">
          <h4>Options: </h4>
          <Form.Check
            className="my-2"
            checked={isAgenda}
            label="Is Agenda"
            onChange={(e) => setIsAgenda(e.target.checked)}
          />
          <h4 className="mt-3">Recipients:</h4>
          <div className="d-flex mb-3">
            <Button
              className="mr-2"
              onClick={() => setSelectedUsersIds(users.map((t) => t.id))}
            >
              Select all
            </Button>
            <Button onClick={() => setSelectedUsersIds([])}>
              Unselect all
            </Button>
          </div>
          {users
            .filter((u) => u.name !== "")
            .map((user) => (
              <Form.Check
                key={user.id}
                checked={selectedUsersIds.some((id) => id === user.id)}
                label={user.name}
                onChange={(e) => {
                  const state = e.target.checked;
                  if (state === false)
                    setSelectedUsersIds((u) =>
                      u.filter((id) => id !== user.id)
                    );
                  if (state === true)
                    setSelectedUsersIds((u) => [...u, user.id]);
                }}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
