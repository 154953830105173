import React from 'react';
import { Card } from 'react-bootstrap';
import { useRouteMatch, Link } from 'react-router-dom';
import { useTournament } from '../hooks/tournaments/useTournament';
import './TournamentsPage.css';

interface Props {}

export const MenuPage: React.FC<Props> = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string; name: string }>();
  const [tournament] = useTournament(id);

  return (
    <>
      {tournament && (
        <div>
          <div className='position-relative'>
            <Link to='/' className='position-absolute start-0 my-3 mx-5'>
              Go Back
            </Link>
            <h1 className='text-center m-3'>{tournament.name}</h1>
            <h3 className='text-center m-2 mb-4'>
              {tournament.date} {tournament.place}
            </h3>
          </div>

          <div className='d-flex flex-column align-items-center'>
            <Card className='m-3 tournament'>
              <Link
                to={`/tournament/${id}/${tournament.name}/matches`}
                style={{ color: 'inherit', textDecoration: 'inherit' }}
              >
                <Card.Body style={{ width: 350 }} className='text-center'>
                  Matches
                </Card.Body>
              </Link>
            </Card>
            <Card className='m-3 tournament'>
              <Link
                to={`/tournament/${id}/${tournament.name}/users`}
                style={{ color: 'inherit', textDecoration: 'inherit' }}
              >
                <Card.Body style={{ width: 350 }} className='text-center'>
                  Users
                </Card.Body>
              </Link>
            </Card>
            <Card className='m-3 tournament'>
              <Link
                to={`/tournament/${id}/${tournament.name}/announcement`}
                style={{ color: 'inherit', textDecoration: 'inherit' }}
              >
                <Card.Body style={{ width: 350 }} className='text-center'>
                  Send Announcement
                </Card.Body>
              </Link>
            </Card>
            <Card className='m-3 tournament'>
              <Link
                to={`/tournament/${id}/${tournament.name}/announcements`}
                style={{ color: 'inherit', textDecoration: 'inherit' }}
              >
                <Card.Body style={{ width: 350 }} className='text-center'>
                  Manage announcements
                </Card.Body>
              </Link>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};
