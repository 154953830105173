import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, Form, FormControl, InputGroup, Table } from 'react-bootstrap';
import { API_URL } from '..';
import { useArmsList } from '../hooks/teams/useArmsList';
import { useUpdateUser } from '../hooks/users/useUpdateUser';
import { SimpleModal } from '../tournaments/SimpleModal';
import { UserT } from '../types/user';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { usePlayers } from '../hooks/players/usePlayers';

interface Props {
  user: UserT;
}

export const User: React.FC<Props> = ({ user }) => {
  const [name, setName] = useState(user.name);
  const [updateUser, status] = useUpdateUser();
  const [armsList] = useArmsList();
  const [isValid, setIsValid] = useState(false);
  const [timeoutHandler, setTimeoutHandler] = useState<NodeJS.Timeout | null>(
    null
  );
  const [showArmModal, setShowArmModal] = useState(false);
  const [showDataModal, setShowDataModal] = useState(false);
  const [newArm, setNewArm] = useState(user.arm_name);
  const [players] = usePlayers(user.id);

  useEffect(() => {
    setName(user.name);
  }, [user.name]);

  useEffect(() => {
    if (timeoutHandler !== null) {
      clearTimeout(timeoutHandler);
      setTimeoutHandler(null);
    }
    if (status === 'success') {
      setIsValid(true);
      setTimeoutHandler(setTimeout(() => setIsValid(false), 5000));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className='d-flex'>
      <InputGroup className='mb-2'>
        <InputGroup.Prepend>
          <Button variant='secondary' onClick={() => setShowDataModal(true)}>
            <FontAwesomeIcon icon={faInfo} />
          </Button>
        </InputGroup.Prepend>
        <FormControl
          placeholder='Team name'
          value={status === 'loading' ? 'Loading...' : name}
          onChange={(e) => setName(e.target.value)}
          onBlur={() => updateUser({ id: user.id, data: { name } })}
          isValid={isValid}
          isInvalid={status === 'error'}
        />
        <InputGroup.Text onClick={() => setShowArmModal(true)}>
          <img
            style={{
              width: 20,
              height: 20,
              alignSelf: 'center',
              cursor: 'pointer',
              userSelect: 'none',
            }}
            src={`${API_URL}/assets/arms/${user.arm_name}`}
            alt='logo'
          />
        </InputGroup.Text>
      </InputGroup>
      <SimpleModal
        confirmButtonText='Change'
        confirmButtonVariant='primary'
        confirmFunction={() => {
          updateUser({ id: user.id, data: { arm_name: newArm } });
          setShowArmModal(false);
        }}
        headerText='Changing the coat of arms'
        setShow={setShowArmModal}
        show={showArmModal}
      >
        <div>
          <h6 style={{ display: 'inline' }}>Current coat of arm: </h6>
          <span>{user.arm_name}</span>
        </div>
        <div className='mb-3'>
          <h6 style={{ display: 'inline' }}>New coat of arm: </h6>
          <span>{newArm}</span>
        </div>

        <Form.Control
          as='select'
          value={newArm}
          onChange={(e) => setNewArm(e.target.value)}
        >
          {armsList &&
            armsList.map((arm) => (
              <option value={arm} key={arm}>
                {arm}
              </option>
            ))}
        </Form.Control>
      </SimpleModal>
      <SimpleModal
        confirmButtonText='Close'
        confirmButtonVariant='secondary'
        confirmFunction={() => setShowDataModal(false)}
        headerText='User data'
        setShow={setShowDataModal}
        show={showDataModal}
      >
        <h5>
          Team name: <h6 className='d-inline'>{user.name}</h6>
        </h5>
        <br />
        <h5>Account data:</h5>
        <div>
          <h6 className='d-inline'>Phone number: </h6>
          <span>{user.phone_number}</span>
        </div>
        <div>
          <h6 className='d-inline'>Email: </h6>
          <span>{user.email}</span>
        </div>
        <br />
        <h5>Players:</h5>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Player</th>
              <th colSpan={2}>T-shirt numbers</th>
            </tr>
          </thead>
          <tbody>
            {players &&
              players.map((player) => (
                <tr>
                  <td>{player.full_name}</td>
                  <td>{player.first_day_number}</td>
                  <td>{player.second_day_number}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </SimpleModal>
    </div>
  );
};
