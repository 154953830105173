import React, { useState } from 'react';
import { useEffect } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useUpdateFinalMatch } from '../hooks/matches/useUpdateFinalMatch';
import { FinalMatchT } from '../types/match';

interface Props {
  data: FinalMatchT;
  displayScoreAndTeams?: boolean;
}

export const FinalMatch: React.FC<Props> = ({
  data,
  displayScoreAndTeams = true,
}) => {
  const [time, setTime] = useState('');
  const [field, setField] = useState(0);
  const [team1Goals, setTeam1Goals] = useState('');
  const [team2Goals, setTeam2Goals] = useState('');
  const [team1PenaltyGoals, setTeam1PenaltyGoals] = useState('');
  const [team2PenaltyGoals, setTeam2PenaltyGoals] = useState('');
  const [updateFinalMatch, status] = useUpdateFinalMatch();
  const [penaltyInputsEnabled, setPenaltyInputsEnabled] = useState(false);

  useEffect(() => {
    if (!data) return;

    if (data.time !== null) setTime(data.time);
    if (data.field !== null) setField(data.field);
    if (data.team_1_goals_scored !== null)
      setTeam1Goals(data.team_1_goals_scored.toString());
    if (data.team_2_goals_scored !== null)
      setTeam2Goals(data.team_2_goals_scored.toString());
    if (data.team_1_penalty_goals_scored !== null)
      setTeam1PenaltyGoals(data.team_1_penalty_goals_scored.toString());
    if (data.team_2_penalty_goals_scored !== null)
      setTeam2PenaltyGoals(data.team_2_penalty_goals_scored.toString());
  }, [data]);

  useEffect(() => {
    const isEnabled = team1Goals !== '' && team1Goals === team2Goals;
    setPenaltyInputsEnabled(isEnabled);
  }, [team1Goals, team2Goals]);

  const save = () => {
    const match: FinalMatchT = {
      ...data,
      time,
      field,
      team_1_goals_scored: parseInt(team1Goals),
      team_2_goals_scored: parseInt(team2Goals),
      team_1_penalty_goals_scored: parseInt(team1PenaltyGoals),
      team_2_penalty_goals_scored: parseInt(team2PenaltyGoals),
    };
    updateFinalMatch(match);
  };

  return (
    <tr>
      <td className='align-middle text-center'>{data.number}</td>
      <td className='align-middle text-center'>{data.group}</td>
      <td className='align-middle'>
        <Form.Control
          style={{ width: '120px', margin: '0 auto' }}
          value={time}
          onChange={(e) => setTime(e.target.value)}
          onBlur={save}
        />
      </td>
      <td className='align-middle text-center'>{data.main_info}</td>
      <td className='align-middle text-center'>{data.side_note}</td>
      <td className='align-middle'>
        <Form.Control
          type='number'
          min={1}
          style={{ width: '70px', margin: '0 auto' }}
          value={field}
          onChange={(e) => setField(parseInt(e.target.value))}
          onBlur={save}
        />
      </td>
      {displayScoreAndTeams && (
        <>
          <td className='align-middle text-center'>{data.team_1_name}</td>
          <td className='align-middle text-center'>{data.team_2_name}</td>
          <td className='align-middle'>
            <Form.Control
              type='number'
              min={0}
              style={{ width: '70px', margin: '0 auto' }}
              value={team1Goals}
              onChange={(e) => setTeam1Goals(e.target.value)}
              onBlur={save}
            />
          </td>
          <td className='align-middle'>
            <Form.Control
              type='number'
              min={0}
              style={{ width: '70px', margin: '0 auto' }}
              value={team2Goals}
              onChange={(e) => setTeam2Goals(e.target.value)}
              onBlur={save}
            />
          </td>
          <td className='align-middle'>
            <Form.Control
              disabled={!penaltyInputsEnabled}
              type='number'
              min={0}
              style={{ width: '70px', margin: '0 auto' }}
              value={team1PenaltyGoals}
              onChange={(e) => setTeam1PenaltyGoals(e.target.value)}
              onBlur={save}
            />
          </td>
          <td className='align-middle'>
            <Form.Control
              disabled={!penaltyInputsEnabled}
              type='number'
              min={0}
              style={{ width: '70px', margin: '0 auto' }}
              value={team2PenaltyGoals}
              onChange={(e) => setTeam2PenaltyGoals(e.target.value)}
              onBlur={save}
            />
          </td>
        </>
      )}
      <td className='align-middle'>
        {status === 'loading' && (
          <Spinner animation='border' role='status'></Spinner>
        )}
        {status === 'success' && <h6 className='text-success'>Saved</h6>}
        {status === 'error' && <h6 className='text-danger'>Error</h6>}
        {status === 'idle' && <h6 className='text-secondary'>Idle</h6>}
      </td>
    </tr>
  );
};
