import axios from 'axios';
import { useQuery } from 'react-query';
import { UserT } from '../../types/user';

export function useUsers(
  tourneyId: string
): [UserT[] | undefined, 'idle' | 'error' | 'loading' | 'success'] {
  const { data, status } = useQuery<UserT[]>(['users', tourneyId], () =>
    axios(`/api/users?tournamentId=${tourneyId}`).then((res) => res.data)
  );

  return [data, status];
}
