import React, { Fragment, useState } from "react";
import { Card, Button } from "react-bootstrap";
import { useRouteMatch, Link } from "react-router-dom";
import { useTournament } from "../hooks/tournaments/useTournament";
import { useUsers } from "../hooks/users/useUsers";
import { SimpleModal } from "../tournaments/SimpleModal";
import { UserT } from "../types/user";
import { User } from "../users/User";

interface Props {}

export const UsersPage: React.FC<Props> = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string; name: string }>();
  const [tournament] = useTournament(id);
  const [users, status] = useUsers(id);
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      {tournament && (
        <div className="position-relative">
          <Link
            to={`/tournament/${id}/${tournament.name}`}
            className="position-absolute start-0 my-3 mx-5"
          >
            Go Back
          </Link>
          <h1 className="text-center m-3">{tournament.name}</h1>
          <h3 className="text-center m-2 mb-4">
            {tournament.date} {tournament.place}
          </h3>
        </div>
      )}

      <Card className="mx-auto my-5" style={{ maxWidth: 1200 }}>
        <Card.Body>
          {status === "success" &&
            users &&
            users.map((user: UserT, key: number) => (
              <User user={user} key={key} />
            ))}
        </Card.Body>
        <Card.Footer className="d-flex justify-content-end">
          <Button variant="info" onClick={() => setShowModal(true)}>
            Invitation links
          </Button>
        </Card.Footer>
      </Card>
      <SimpleModal
        confirmButtonText="Close"
        confirmButtonVariant="warning"
        confirmFunction={() => setShowModal(false)}
        headerText="Invitation links"
        show={showModal}
        setShow={setShowModal}
        size="xl"
      >
        {users &&
          users.map((user, i) => {
            const url = `${process.env.REACT_APP_TEAM_APP_URL}/registration?code=${user.registration_code}`;
            return (
              <Fragment key={user.id}>
                <div className="d-flex justify-content-between px-3">
                  <h6>{user.name}</h6>
                  <a href={url}>{url}</a>
                </div>
                {i !== users.length - 1 && <hr />}
              </Fragment>
            );
          })}
      </SimpleModal>
    </div>
  );
};
