import {
  useMutation,
  MutationStatus,
  UseMutateFunction,
  useQueryClient,
} from 'react-query';
import axios from 'axios';

export interface AnnouncementPayloadI {
  content: string;
  isAgenda: boolean;
  users: number[];
}

export function useCreateAnnouncement(
  tourneyId: string
): [
  UseMutateFunction<unknown, unknown, AnnouncementPayloadI, unknown>,
  MutationStatus,
  boolean
] {
  const queryClient = useQueryClient();
  const { mutate, status, isLoading } = useMutation(
    (announcementPayload: AnnouncementPayloadI) =>
      axios
        .post(
          `/api/announcements?tournamentId=${tourneyId}`,
          announcementPayload
        )
        .then((res) => res.data),
    {
      onSuccess() {
        queryClient.invalidateQueries(['announcements', tourneyId]);
      },
    }
  );

  return [mutate, status, isLoading];
}
