import React from 'react';
import { Table } from 'react-bootstrap';
import { useGroupMatches } from '../hooks/matches/useGroupMatches';
import { GroupMatch } from './GroupMatch';

interface Props {
  tourneyId: number;
}

export const Schedule: React.FC<Props> = ({ tourneyId }) => {
  const [matches] = useGroupMatches(tourneyId);

  return (
    <div className='my-3'>
      <h2 className='text-center'>Schedule</h2>
      <Table bordered>
        <thead>
          <tr>
            <th>No.</th>
            <th>Time</th>
            <th>Group</th>
            <th>Field</th>
            <th colSpan={2}>Teams</th>
            <th colSpan={2}>Score</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {matches?.map((match, key) => (
            <GroupMatch key={key} match={match} />
          ))}
        </tbody>
      </Table>
    </div>
  );
};
