import React from 'react';
import { Container } from 'react-bootstrap';
import { FinalMatchesTable } from '../finals/FinalMatchesTable';

interface Props {
  tourneyId: number;
}

export const KnockoutStagePage: React.FC<Props> = ({ tourneyId }) => {
  return (
    <Container fluid>
      <h1 className='text-center mt-3'>Knockout Stage</h1>
      <FinalMatchesTable tourneyId={tourneyId} />
    </Container>
  );
};
