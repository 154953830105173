import React from 'react';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTournament } from '../hooks/tournaments/useTournament';
import { useUpdateTournament } from '../hooks/tournaments/useUpdateTournament';
import { useGroupContext } from '../shared/groupContext';
import { GroupStagePage } from './GroupStagePage';
import { KnockoutStagePage } from './KnockoutStagePage';

interface Props {}

export const TournamentPage: React.FC<Props> = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string; name: string }>();
  const context = useGroupContext();
  const queryClient = useQueryClient();
  const [tournament] = useTournament(id);
  const [updateTournament] = useUpdateTournament();
  useEffect(() => {
    queryClient.invalidateQueries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (context?.state.finishedQueries !== 2 || !tournament) return;

    updateTournament(
      {
        id: tournament.id,
        data: { status: 'knockout stage' },
      },
      {
        onSuccess() {
          queryClient.invalidateQueries(['finalMatches', tournament.id]);
        },
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.state.finishedQueries]);

  return (
    <>
      {tournament && (
        <div className='position-relative'>
          <Link
            to={`/tournament/${id}/${tournament.name}`}
            className='position-absolute start-0 my-3 mx-5'
          >
            Go Back
          </Link>
          <h1 className='text-center m-3'>{tournament.name}</h1>
          <h3 className='text-center m-2 mb-4'>
            {tournament.date} {tournament.place}
          </h3>
        </div>
      )}
      {tournament && tournament.status === 'group stage' && (
        <GroupStagePage tournamentId={tournament.id} />
      )}
      {tournament && tournament.status === 'knockout stage' && (
        <KnockoutStagePage tourneyId={tournament.id} />
      )}
    </>
  );
};
